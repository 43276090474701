 /*
 * Global application theme.
 * Framework overrides and customization goes here.
 */
@use '@angular/material' as mat;


// stylelint-disable-next-line selector-max-universal
* {
  box-sizing: border-box;
}

html,
body {
  display: flex;
  flex-direction: column;
  margin: 0;
  height: 100%;
}

/* Sup line height fix */
sup {
  vertical-align: top;
  font-size: 0.6em;
}

/* Bold / Strong */
.mat-body-strong,
.mat-subtitle-2 {
  font: 550 14px/24px Roboto, "Helvetica Neue", sans-serif !important;
}

.inactive-status {
  color: gray;
}

.active-status {
  color: green;
}

/* Colors */
.gray {
  color: gray;
}

.green {
  color: green;
}

.primary {
  color: mat.get-color-from-palette($app-primary);
}

.primary-visited {
  color: mat.get-color-from-palette($app-primary, 200);
}

.orange {
  color: orange;
}

.red {
  color: red;
}


quill-editor {
  display: block;
}

.ql-editor:focus {
  border: 1px solid #80bdff !important;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
}

/* QUILL TEXT EDITOR */
quill-editor {
  background-color: #f6f8f9 !important;
}

quill-editor .ql-toolbar {
  background-color: #e6e6e6 !important;
  border: unset !important;
}

quill-editor .ql-container {
  border: unset !important;
}

.ql-editor:focus {
  border: unset !important;
  box-shadow: unset !important;
  outline: 1px solid mat.get-color-from-palette($app-primary) !important;
}

/* Set dropdown font-families */
[quill-editor-toolbar] .ql-font span[data-label="Aref Ruqaa"]::before {
  font-family: "Aref Ruqaa";
}

[quill-editor-toolbar] .ql-font span[data-label="Mirza"]::before {
  font-family: "Mirza";
}

[quill-editor-toolbar] .ql-font span[data-label="Roboto"]::before {
  font-family: "Roboto";
}

/* Set content font-families */
.ql-font-mirza {
  font-family: "Mirza";
}

.ql-font-aref {
  font-family: "Aref Ruqaa";
}

.ql-font-roboto {
  font-family: "Roboto";
}

/* Drag scroll */
drag-scroll div div {
  overflow: hidden !important;
}

@media only screen and (max-width: 959px) {
  drag-scroll div div {
    overflow: scroll !important;
  }
}

/* No selection */
.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

/* Clikable icon */
.clickable-icon {
  color: mat.get-color-from-palette($app-primary);
  cursor: pointer;
}

/* Backdrop color for dialogs */
.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.65);
}

/* Shadows of accordion */
mat-expansion-panel:not([class*="mat-elevation-z"]) {
  box-shadow: 0px 1px 1px -3px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 0px 1px 0px rgba(0, 0, 0, 0.12) !important;
}

/* Loader */
.loader {
  width: 100%;
  text-align: center;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.loader .mat-mdc-progress-spinner {
  margin-left: auto;
  margin-right: auto;
}

.loader .mat-mdc-progress-spinner,
.loader .mat-mdc-spinner {
  margin-bottom: 12px;
}

.loader span {
  color: mat.get-color-from-palette($app-primary, 50);
}

/* Table */
.table-overview {
  min-height: 175px;
  overflow-x: auto;
}

.table-overview p {
  text-align: center;
}

.table-overview mat-table mat-header-row,
.table-overview mat-table mat-row,
.table-overview mat-table mat-footer-row {
  min-width: 100%;
  display: inline-flex !important;
}

.table-overview mat-table mat-row:hover:not(.disabled) {
  background-color: mat.get-color-from-palette($app-accent, 100) !important;
  cursor: pointer;
}

.table-overview-read mat-table mat-row:hover:not(.disabled) {
  background-color: mat.get-color-from-palette($app-accent, 100) !important;
  cursor: unset;
}

.table-overview mat-table mat-row:focus {
  outline: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.table-overview mat-table mat-row.disabled {
  background-color: mat.get-contrast-color-from-palette($app-primary, 300);
}

/* Tab */
.mat-mdc-tab-body-content {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.mat-mdc-tab-labels {
  border-bottom: 1px solid rgba(0,0,0,.12);
}

/* Table */
.mat-mdc-table * span,
.mat-mdc-table * a {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* Title */
.mat-mdc-card-title {
  font-size: 21px;
  font-weight: 400;
  min-height: 32px;
}

.mat-mdc-card-title .mat-mdc-icon-button {
  width: 32px;
  height: 32px;
  line-height: 32px;
}
.mat-mdc-card-header-text {
  width: 100%;
}

/* Documents */
.full-screen-dropzone ngx-file-drop * {
  top: 2.5% !important;
  left: 2.5% !important;
  position: absolute !important;
  width: 95% !important;
  height: 95% !important;
}

/* Link */
.link {
  cursor: pointer;
  color: mat.get-color-from-palette($app-primary);
  text-decoration: none;
}

/* Cards list */
.card-list .mat-mdc-card-content .mat-mdc-list {
  padding-top: 0px;
}

.card-list .mat-mdc-card-title {
  margin-bottom: 0px;
}

/* Detail list */
.detail-list {
  overflow-x: hidden;
  max-width: 100%;
}

.detail-list div div:first-child {
  flex: 0 0 225px;
  box-sizing: border-box;
  min-width: 225px;
}

.detail-list div div:nth-child(2) {
  flex: 2 1 100px;
  box-sizing: border-box;
}

/* Audit list / trail / log */
.audit-list vf-audit-list-item:first-of-type .mat-mdc-list-item .mat-mdc-list-item-unscoped-content .audit-top-line {
  background-color: transparent !important;
}

.audit-list vf-audit-list-item .mat-mdc-list-item {
  height: auto !important;
  min-height: 55px;
  padding-bottom: 10px;
}

/* Add dialog */
@media only screen and (max-width: 959px) {
  .add-dialog {
    width: 80vw;
  }
}

@media only screen and (min-width: 960px) {
  .add-dialog {
    min-width: 25vw;
  }
}

/* Edit dialog */
@media only screen and (max-width: 959px) {
  .edit-dialog {
    width: 80vw;
  }
}

@media only screen and (min-width: 960px) {
  .edit-dialog {
    min-width: 25vw;
  }
}


/* Full screen dialog */
.full-screen-dialog .mat-mdc-dialog-container {
  overflow: hidden !important; 
}

/* Information dialog */
@media only screen and (max-width: 959px) {
  .information-dialog {
    width: 80vw;
  }
}

@media only screen and (min-width: 960px) {
  .information-dialog {
    min-width: 25vw;
  }
}

.information-dialog .mat-mdc-dialog-container * .mat-mdc-dialog-title {
  margin-bottom: 12px;
}

.information-dialog .mat-mdc-dialog-container * .mat-mdc-dialog-actions {
  padding-top: 8px;
  padding-bottom: 12px;
}

/* Audit dialog */
@media only screen and (max-width: 959px) {
  .audit-dialog {
    width: 80vw;
  }
}

@media only screen and (min-width: 960px) {
  .audit-dialog {
    min-width: 25vw;
  }
}

.audit-dialog .mat-mdc-dialog-container * .mat-mdc-dialog-title {
  margin-bottom: 12px;
}

.audit-dialog .mat-mdc-dialog-container * .mat-mdc-dialog-actions {
  padding-top: 8px;
  padding-bottom: 12px;
}

 /* Custom slider */
 
 .custom-slider .ngx-slider .ngx-slider-bar {
  background: rgba(0, 0, 0, 0.15);
  height: 2px;
}
.custom-slider .ngx-slider .ngx-slider-selection {
  background: mat.get-color-from-palette($app-primary);
}

.custom-slider .ngx-slider .ngx-slider-pointer {
  width: 1rem;
  height: 1rem;
  top: -0.5rem;
  bottom: 0;
  background-color: mat.get-color-from-palette($app-primary);
  border-radius: 50%;
  -webkit-transition: -webkit-transform 200ms;
  transition: transform 200ms;
}

.custom-slider .ngx-slider .ngx-slider-pointer.ngx-slider-active {
  -webkit-transform: scale(1.4);
  transform: scale(1.4);
  outline: none !important;
}

.custom-slider .ngx-slider .ngx-slider-pointer:after {
  display: none;
}

.custom-slider .ngx-slider .ngx-slider-bubble {
  bottom: 14px;
}

.custom-slider .ngx-slider .ngx-slider-limit,
.ngx-slider .ngx-slider-bubble.ngx-slider-limit {
  font-weight: 400 !important;
  font-size: 0.875rem !important;
  color: mat.get-color-from-palette($app-primary, 200) !important;
  opacity: 0.5;
}

.ngx-slider .ngx-slider-bubble {
  color: mat.get-color-from-palette($app-primary, 900) !important;
  font-size: 0.875rem !important;
}

.custom-slider .ngx-slider .ngx-slider-tick {
  width: 1px;
  height: 10px;
  margin-left: 4px;
  border-radius: 0;
  background: rgba(24, 22, 23, 0.26);
  top: -1px;
}

.ngx-slider .ngx-slider-tick.ngx-slider-selected {
  background: mat.get-color-from-palette($app-primary);
}

/* Status slider */
.status-slider .ngx-slider .ngx-slider-bar {
  background: rgba(0, 0, 0, 0.15);
  height: 2px;
}
.status-slider .ngx-slider .ngx-slider-selection {
  background: mat.get-color-from-palette($app-primary);
}

.status-slider .ngx-slider .ngx-slider-pointer {
  width: 1rem;
  height: 1rem;
  top: -0.5rem;
  bottom: 0;
  background-color: mat.get-color-from-palette($app-primary);
  border-radius: 50%;
  -webkit-transition: -webkit-transform 200ms;
  transition: transform 200ms;
  cursor: default;
}

.status-slider .ngx-slider .ngx-slider-pointer.ngx-slider-active {
  -webkit-transform: scale(1.4);
  transform: scale(1.4);
  outline: none !important;
  cursor: default;
}

.status-slider .ngx-slider .ngx-slider-pointer:after {
  display: none;
  cursor: default;
}

.status-slider .ngx-slider .ngx-slider-bubble {
  bottom: 14px;
}

.status-slider .ngx-slider .ngx-slider-limit,
.ngx-slider .ngx-slider-bubble.ngx-slider-limit {
  font-weight: 400 !important;
  font-size: 0.875rem !important;
  color: mat.get-color-from-palette($app-primary, 200) !important;
  opacity: 0.5;
}

.ngx-slider .ngx-slider-bubble {
  color: mat.get-color-from-palette($app-primary, 900) !important;
  font-size: 0.875rem !important;
}

.status-slider .ngx-slider .ngx-slider-tick {
  width: 1px;
  height: 10px;
  margin-left: 4px;
  border-radius: 0;
  background: rgba(24, 22, 23, 0.26);
  top: -1px;
  cursor: default;
}

.status-slider .ngx-slider .ngx-slider-tick.ngx-slider-selected {
  background: mat.get-color-from-palette($app-primary);
}

/* Count badge */
.tabBadgeCount .mat-mdc-badge-content.mat-mdc-badge-active {
  font-weight: 400;
  right: -28px;
  top: -14px;
}

/* Action badge */
.tabBadgeAction .mat-mdc-badge-content.mat-mdc-badge-active {
  width: 15px;
  height: 15px;
  line-height: 15px;
}

/* Button list */
@media only screen and (min-width: 960px) {
  .button-list {
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
}

@media only screen and (max-width: 959px) {
  .button-list {
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-top: 0px !important;
    margin-bottom: 2rem !important;
  }
}

// Give all a elements the pointer, regardless the 'href' presence
a {
  cursor: pointer;
}

/* Header filter select field */
.filter-field {
  font-size: 0.875rem;
}

.filter-field .mat-mdc-form-field-infix {
  border-top: 0;
}
  
.child-detail-link {
  cursor: pointer;
  text-decoration: none;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: blue;
}