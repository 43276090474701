/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */
@use '@angular/material' as mat;

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

// Theme variables, must be included before the libraries to allow overriding defaults
@import "theme/theme-variables";

// 3rd party libraries
@import "material-design-icons-iconfont/src/material-design-icons";

// Angular Material custom theme
// The mixins below must be included once so we separated them from the variables
@import "@angular/material/theming";
@include mat.core();

// Theme customization
@import "theme/theme";

// Overwrite default tailwindcss properties in order to correct display markdown
@layer base {
    ol,
    ul {
      list-style: disc;
      margin-block-end: 14px;
      margin-block-start: 14px;
      padding-inline-start: 40px;
    }
  }